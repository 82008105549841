import Link from "next/link";
import styleComponent from "./ImageLink.module.scss";
import RC1Image from "@/components/RC1Image/RC1Image";

interface ImagesLinkProps {
	imageLinks: any[];
}

const ImagesLink = ({ imageLinks }: ImagesLinkProps): JSX.Element => {
	return (
		<ul className={styleComponent.imageLink}>
			{imageLinks &&
				imageLinks.map((link) => (
					<li key={link.fields.label}>
						<Link
							prefetch={false}
							href={link.fields.destinationUrl}
						>
							<a rel="me">
								<RC1Image
									src={link.fields.image.fields.file.url}
									alt={link.fields.image.fields.title}
									width={35}
									height={25}
								/>
							</a>
						</Link>
					</li>
				))}
		</ul>
	);
};

export default ImagesLink;
