import React, { FunctionComponent, useReducer, useEffect } from "react";
import { CartContext, CartReducer, UpdateQtyManualData } from "./";
import { ICartList, ITierOperator } from "interfaces";
import { checkoutClient } from "@/services/nacelleShop";
import { IDeal } from "interfaces/deal.interfaces";
import {
	handleDealsInCart,
	middlewareForDeal,
	watchForDeal,
} from "@/helpers/utilCart";

export interface CartState {
	cart: ICartList[];
	deal: IDeal[];
	tierOperator: ITierOperator[];
	shopId: string | null;
	total: number;
	totalPoints: number;
}

const CART_INITIAL_STATE: CartState = {
	cart: [],
	deal: [],
	tierOperator: [],
	shopId: null,
	total: 0,
	totalPoints: 0,
};

interface Props {
	children: React.ReactNode;
}

export const CartProvider: FunctionComponent<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(
		CartReducer,
		CART_INITIAL_STATE,
		() => {
			if (typeof window !== "undefined") {
				const localData = localStorage.getItem("cart");
				return localData ? JSON.parse(localData) : { cart: [] };
			}
		}
	);

	useEffect(() => {
		const checkShopId = async (id: string) => {
			try {
				let response: any = await checkoutClient.get({ id });
				if (response.completed === true) {
					dispatch({ type: "[Cart] - Remove shopId" });
					dispatch({ type: "[Cart] - Clear all cartList" });
				}
			} catch (error) {
				return null;
			}
		};

		if (state?.shopId) {
			checkShopId(state?.shopId);
		}
		if (state.cart) {
			localStorage.setItem("cart", JSON.stringify(state));
		}
	}, [state]);

	const addShopId = (id: string) => {
		dispatch({ type: "[Cart] - Add shopId", payload: { id } });
	};

	const addToCart = (productCart: ICartList) => {
		dispatch({ type: "[Cart] - Add to cart", payload: productCart });
	};

	const removeFromCart = (cartItemId: string | number) => {
		dispatch({ type: "[Cart] - Remove from cart", payload: cartItemId });
	};

	const updateCartList = (productCart: ICartList) => {
		dispatch({ type: "[Cart] - Update cart list", payload: productCart });
	};

	const updateQtyOnCartAddManual = (payload: UpdateQtyManualData) => {
		dispatch({
			type: "[Cart] - Update quantity on cart add manual",
			payload,
		});
	};

	const updateQtyOnCartRemove = (cartItemId: string | number) => {
		dispatch({
			type: "[Cart] - Update quantity on item cart removed",
			payload: cartItemId,
		});
	};

	const setDealOnState = (deals: any[], total: number, cart: ICartList[]) => {
		dispatch({
			type: "[Cart] - Set deal",
			payload: {
				deal: middlewareForDeal(
					watchForDeal(deals, total, cart) || [],
					cart
				),
			},
		});
	};

	const removeDeal = (deal: string | number) => {
		dispatch({ type: "[Cart] - Remove deal", payload: deal });
	};

	const updateFullCart = (cart: ICartList[], total?: number) => {
		if (total) {
			return dispatch({
				type: "[Cart] - Update full cart",
				payload: handleDealsInCart(cart, total, cart),
			});
		}

		dispatch({
			type: "[Cart] - Update full cart",
			payload: cart,
		});
	};

	const updateCartListHasDeal = () => {
		dispatch({ type: "[Cart] - Update cart list has deal" });
	};

	const updateCartTotal = (total: number) => {
		dispatch({ type: "[Cart] - Update cart total", payload: { total } });
	};

	const setSwellPoints = (points: number) => {
		dispatch({ type: "[Cart] - Set swell points", payload: { points } });
	};

	return (
		<CartContext.Provider
			value={{
				...state,
				// method
				addShopId,
				addToCart,
				removeFromCart,
				updateFullCart,
				updateCartTotal,
				updateCartList,
				updateQtyOnCartAddManual,
				updateQtyOnCartRemove,
				updateCartListHasDeal,
				setDealOnState,
				removeDeal,
				setSwellPoints,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};
