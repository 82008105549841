import { FunctionComponent, useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";
import styles from "./TopNav.module.scss";
import ImagesLink from "../ImageLink/ImageLink";
import SlideCustomText from "../SlideCustomText/SlideCustomText";
import { GlobalContext } from "context/global/GlobalContext";

interface TopNavProps {
	topNavData: any;
}

const TopNav: FunctionComponent<TopNavProps> = ({
	topNavData,
}: TopNavProps) => {
	const [prevScreenPos, setPrevScreenPos] = useState<number>(0);
	const [visible, setVisible] = useState<boolean>(true);
	const { setProduct } = useContext(GlobalContext);
	const router = useRouter();

	useEffect(() => {
		setProduct(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.asPath]);

	const handleScroll = () => {
		const currentScreenPos = window.scrollY;

		setVisible(
			(prevScreenPos > currentScreenPos &&
				prevScreenPos - currentScreenPos > 70) ||
				currentScreenPos < 70
		);

		setPrevScreenPos(currentScreenPos);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	});

	return (
		<div className={visible ? styles.topNav : styles.topNavScroll}>
			<div className={`d-md-block ${styles.slideText}`}>
				{topNavData?.fields?.textBanners && (
					<SlideCustomText
						textBannerData={topNavData?.fields?.textBanners}
					/>
				)}
			</div>
			<div className={styles.imageRow}>
				{topNavData?.fields?.imageLinks && (
					<ImagesLink imageLinks={topNavData?.fields?.imageLinks} />
				)}
			</div>
		</div>
	);
};

export default TopNav;
